<template>

  <!-- id : contents : (S) -->
  <div id='contents' >


    <div class="newmember-msg-box">
      e-CLUB 에 가입하신 새로운 회원님들을 소개합니다.
    </div>
    <div class="board-search-wrap st-new-member">
      <div class="tit-box"><span>신규 회원 수  <em class="st2">{{ memberList.totalElements }}</em> 명</span> ( 가입 기간: {{this.param.fromDate}} ~ {{this.param.toDate}} )</div>
      <div class="search-box">
        <form action="">
          <span class="mr15 fnt18">가입시기</span>
          <select v-model="searchMonth" class="input-st1 st-selectbox wd150">
            <option :value="1">1개월</option>
            <option :value="3">3개월</option>
            <option :value="6">6개월</option>
          </select>
        </form>
      </div>
    </div>


      <div class="tb-01 st2 pc-only">
        <table>
          <colgroup><col style="width:100px"><col style="width:100px"><col style="width:130px"><col style="width:*"><col style="width:180px"><col style="width:130px"><col style="width:130px"></colgroup>
          <thead>
          <tr>
            <th>번호</th>
            <th>사진</th>
            <th>이름</th>
            <th>자택주소</th>
            <th>휴대폰번호</th>
            <th>분과</th>
            <th>동호회</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(member, idx) in memberList.content" :key="idx" @mouseover="onMouseOver(idx)" @mouseleave="onMouseLeave" :style="idx===overIdx? 'background-color:rgb(224, 224, 224)':''">
            <td class="al-center">{{ idx + 1 + ((pagination.currentPage-1) * pagination.pageSize) }}</td>
            <td class="al-center">
              <img class="member-list-box" v-if="member.photoFile==null" src="../../../assets/front/images/icon/member.jpg"/>
              <img class="member-list-box" v-else :src="`/attachment/user/photo/${member.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
            </td>
            <td class="al-center">
              <a href="javascript:;" class="btnPeopleDtlOver" @click="onClickUser(member.userId)" :style="idx===overIdx? 'font-weight:bold':''">
                <!--<span class="icon-new st2"></span>--> {{ member.name }}
              </a>
            </td>
            <!-- btnPeopleDtlOver 스크립트 있음 -->
            <td><div class="txt330 ellipsis">{{ member.address }} {{ member.addressDetail }}</div></td>
            <td class="al-center">{{ phoneFormatter(member.mobileNo) }}</td>
            <td class="al-center">{{ memberSubdivision(member.departmentGroup) }}</td>
            <td class="al-center">{{ memberClub(member.clubGroup )}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="mb-only member-mb-list">
        <div class="row-box" v-for="(member, idx) in memberList.content" :key="idx"  @click="onClickUser(member.userId)"  >
          <div class="profile-box">
            <img class="member-list-box" v-if="member.photoFile==null" src="../../../assets/front/images/icon/member.jpg"/>
            <img class="member-list-box" v-else :src="`/attachment/user/photo/${member.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
          </div>
          <div class="text-box">
            <div class="name-box">
              <a href="javascript:;" class="btnPeopleDtlOver" @click="onClickUser(member.userId)" :style="idx===overIdx? 'font-weight:bold':''">
                {{ member.name }}
              </a>
            </div>
            <div class="part-box">
              {{ memberSubdivision(member.departmentGroup) }}
              {{ memberClub(member.clubGroup )}}
            </div>
          </div>
        </div>
      </div>
      <!-- class : pagination (S) -->
      <DgPagination v-model="pagination" :value="memberList" @pageSelected="pageSelected"/>
      <!-- class : pagination (E) -->

      <CommonPopup v-if="memberPopupOpen" :tap="popupTab" :userId="userId" @click:bgDimClose="popupMemberList" ></CommonPopup>
  </div>
</template>

<script>
import userApi from "@/api/UserApi";
import CommonPopup from "@/views/include/common_popoup";
import DgPagination from '@/views/component/DgPagination'

export default {
  name: "MemberNew",
  components:{
    CommonPopup,
    DgPagination
  },
  data(){
    return {
      currentTab:1,
      tabs:['회원현황','신규회원','연간일정'],
      memberPopupOpen: false,
      popupTab: 'detail',

      memberList:[],
      userId: null,
      overIdx: null,

      searchMonth:1,
      param: {
        fromDate: null,
        toDate: null,
        page: 1
      },

      pagination:{
        currentPage: 1,
        pageSize:10,
        pageGroupSize: 5,
        totalPages:0,
        totalElements:0
      },
    }
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false
    },
    reload:{
      type:Boolean
    }
  },
  created() {
    this.onClickSearch()
    this.$emit('click:refreshReload', false);
  },
  watch:{
    isOpen(e){
      if(!e){
        this.memberPopupOpen=false
      }
    },
    reload(e){
      if(e) {
        this.searchMonth=1
        this.onClickSearch();
        this.$emit('click:refreshReload', false);
      }
    },
    searchMonth(){
      this.pagination.currentPage = 1
      this.onClickSearch()
    }
  },
  methods:{
    pageSelected(pagination){
      this.pagination.currentPage = pagination.currentPage;
      this.onClickSearch();
    },
    onClickUser(userId){
      this.userId = userId
      this.popupMemberList(true)
    },
    onMouseOver(idx){
      this.overIdx=idx;
    },
    onMouseLeave(){
      this.overIdx=null;
    },
    popupMemberList(e) {
      this.memberPopupOpen = e
      this.$emit('click:bgDim', this.memberPopupOpen)
    },
    onClickSearch(){
      let moment = require('moment')
      this.param.fromDate = moment().subtract(this.searchMonth,'months').format('YYYY-MM-DD')
      this.param.toDate = moment().format('YYYY-MM-DD')
      const params = {...this.param};

      params.searchState = 'STATE_NORMAL'
      params.size = this.pagination.pageSize
      params.page = this.pagination.currentPage

      userApi.list(params).then(data => {
        this.memberList = data;
        this.pagination.totalElements = data.totalElements
        let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0) ;
        this.pagination.totalPages = totalPages;
      })
    },
    onClickTab(val){
      switch (val){
        case 0 :
          this.$router.push(`/member`).catch(()=>{});
          break;
        case 1 :
          this.$router.push(`/memberNew`).catch(()=>{});
          break;
        case 2 :
          this.$router.push(`/schedule`).catch(()=>{});
          break;
      }
    },
    phoneFormatter(val){
      if (val != null) {
        if (val.length == 11) {
          return val.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        } else if (val.length == 8) {
          return val.replace(/(\d{4})(\d{4})/, "$1-$2");
        } else {
          if (val.indexOf("02") == 0) {
            return val.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          } else {
            return val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          }
        }
      }
    },
    memberClub(val){
      if(val != null) {
        let clubLength = val.length
        let userClubs = clubLength > 0 ? val[0].groupName : ''
        if (clubLength > 1) {
          userClubs += " 외 " + (clubLength - 1)
        }
        return userClubs
      }else{
        return '';
      }
    },
    memberSubdivision(val){
      if(val!=null){
        return val.length > 0? val[0].groupName:''
      }
    }
  }
}
</script>

<style scoped>

</style>