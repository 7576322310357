<template>

  <!-- id : contents : (S) -->
  <div id='contents' >



    <!-- board-dtl-box (S) -->
    <div class="board-dtl-box">
      <div class="hd-box">
        <div class="tit"><span if="article.notice">{{ article.boardCategory!=null?"["+article.boardCategory.categoryName+"]":'' }} </span>{{ article.title }}</div>
        <div class="exp">
          <span class="date">{{ formatDate(article.createdAt) }}</span>
          <span class="date">
            <div class="send-msg-menu-box" :class="{open:showMemberDetail}" @click="showMemberDetail = !showMemberDetail"><!-- open 클래스 추가 시 버튼 노출 -->
              <a href="javascript:;" class="btnSendMenu">{{ article.writer }}</a>
               <div class="btn-list-box">
                <a href="javascript:;" class="btnPeopleDtlOver" @click="onClickUser(article.createdBy, 'detail')">회원 정보</a>
                <a href="javascript:;" class="btnMemoSendOver" @click="onClickUser(article.createdBy, 'message')">쪽지보내기</a>
              </div>
            </div>
          </span>
          <span class="date">조회 {{ article.hits==null? 0:article.hits }}</span>
        </div>
      </div>
      <div class="text-body-box">
        <div v-html="article.contents" v-if="!article.mobile"></div>
        <div v-html="article.contents.replace(/(?:\r\n|\r|\n)/g, '<br />')" v-else></div>
      </div>
      <div v-if="article.articleAttachments.length>0" class="attach-list-box">
        <ul>
          <li v-for="attach in article.articleAttachments" :key="attach.attachId">
            <a :href="`${apiEndPoint}/board/${article.boardId}/article/${article.articleId}/file/${attach.fileId}`">{{ attach.fileRealName }}</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- board-dtl-box (E) -->

    <CommonPopup v-if="memberList" :tap="popupTab" :userId="userId" :groupId=null @click:bgDimClose="popupMemberList"></CommonPopup>

  </div>
</template>

<script>
import CommonPopup from "@/views/include/common_popoup";
import articleApi from "@/api/ArticleApi";
import moment from "moment";

export default {
  name: "Schedule",
  components:{
    CommonPopup,
  },
  props:{
    boardId:{
      type:Number
    },
    reload:{
      type:Boolean
    }
  },
  data(){
    return {
      apiEndPoint:process.env.VUE_APP_BASE_API,

      parentMenu:null,
      tabIndex:0,

      memberPopupOpen: false,
      popupTab: 'detail',
      memberList: false,

      article:{articleAttachments:[]},
      userId:null,
      showMemberDetail: false,

      param : {
        size:1
      }
    }
  },
  created() {
    this.getArticles();
  },
  // created() {
  //   alert('schedule - created')
  //   this.tabIndex = this.$route.params.tapNo
  //   this.parentMenu = this.$route.query.upper
  //
  //   articleApi.list(this.boardId, this.param).then(data => {
  //     this.article = data.content[0];
  //   });
  //
  //   this.$emit('click:refreshReload', false);
  // },
  watch:{
    isOpen:{
      type:Boolean,
      default:false
    },
    reload(e){
      if(e) {
        this.getArticles();
      }
    },
  },
  methods:{
    formatDate(value) {
      return moment(value).format('YYYY.MM.DD');
    },
    getArticles(){
      articleApi.list(this.boardId, this.param).then(data => {
        this.article = data.content[0];
      });
      this.$emit('click:refreshReload', false);
    },
    onClickRevise(){
      this.$emit('click:title','write',this.articleId)
    },
    popupMemberList(e){
      this.memberList = e
      this.$emit('click:bgDim', this.memberList)
    },
    onClickUser(userId, popStatus){
      this.userId=userId
      this.popupTab=popStatus
      this.popupMemberList(true)
    },
    isOpen(e){
      if(!e){
        this.memberList=false
      }
    },
    onClickTab(val){
      switch (val){
        case 0 :
          this.$router.push(`/member`).catch(()=>{});
          break;
        case 1 :
          this.$router.push(`/memberNew`).catch(()=>{});
          break;
        case 2 :
          this.$router.push(`/schedule`).catch(()=>{});
          break;
      }
    },
  }
}
</script>

<style scoped>

</style>