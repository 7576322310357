<template>

  <!-- id : contents : (S) -->
  <div id='contents' >


    <div class="board-search-wrap">
      <div class="tit-box">총 회원 수  <em class="st2">{{ memberList.totalElements }}</em> 명</div>
      <div class="search-box">
        <!-- <select name="" id="" class="input-st1 st-selectbox wd180 mr10" v-model="param.searchManager">
          <option value="">운영진</option>
          <option v-for="manager in managerItems" :key="manager.roleId" >{{ manager.roleName }}  </option>
        </select> -->
        <select name="" id="" class="input-st1 st-selectbox wd180 mr10" v-model="param.searchDepartment">
          <option value="">분과 </option>
          <option v-for="department in departmentItems" :key="department.groupId" :value="department.groupId">{{ department.groupName }} </option>
        </select>
        <select name="" class="input-st1 st-selectbox wd180 mr10" v-model="param.searchClub">
          <option value="">동호회 </option>
          <option v-for="club in clubItems" :key="club.groupId" :value="club.groupId">{{ club.groupName }} </option>
        </select>
        <input type="text" v-model="param.searchKeyword" placeholder="검색어를 입력해주세요." class="input-st1 wd240 mr10" @keydown="onKeydown">
        <a href="javascript:;" class="btn-s mb-wd100" @click="onClickBeforeSearch">검색</a>
      </div>
    </div>

    <div class="tb-01 st2 pc-only">
      <table>
        <colgroup><col style="width:100px"><col style="width:100px"><col style="width:130px"><col style="width:*"><col style="width:180px"><col style="width:130px"><col style="width:130px"></colgroup>
        <thead>
        <tr>
          <th>번호</th>
          <th>사진</th>
          <th>이름</th>
          <th>자택주소</th>
          <th>휴대폰번호</th>
          <th>분과</th>
          <th>동호회</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(member, idx) in memberList.content" :key="idx" @mouseover="onMouseOver(idx)" @mouseleave="onMouseLeave" :style="idx===overIdx? 'background-color:rgb(224, 224, 224)':''">
          <td class="al-center">{{ idx + 1 + ((pagination.currentPage-1) * pagination.pageSize) }}</td>
          <td class="al-center">
            <img class="member-list-box" v-if="member.photoFile==null" src="../../../assets/front/images/icon/member.jpg"/>
            <img class="member-list-box" v-else :src="`/attachment/user/photo/${member.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
          </td>
          <td class="al-center">
            <a href="javascript:;" class="btnPeopleDtlOver" @click="onClickUser(member.userId)" :style="idx===overIdx? 'font-weight:bold':''">
              <span class="icon-new st2" v-if="isNew(member.createdAt)"></span> {{ member.name }}
            </a>
          </td>
          <!-- btnPeopleDtlOver 스크립트 있음 -->
          <td><div class="ellipsis" style="width: 375px;">{{ member.address }} {{ member.addressDetail }}</div></td>
          <td class="al-center">{{ phoneFormatter(member.mobileNo) }}</td>
          <td class="al-center">{{ memberSubdivision(member.departmentGroup) }}</td>
          <td class="al-center">{{ memberClub(member.clubGroup )}}</td>

        </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-only member-mb-list">
      <div class="row-box" v-for="(member, idx) in memberList.content" :key="idx"  @click="onClickUser(member.userId)"  >
        <div class="profile-box">
          <img class="member-list-box" v-if="member.photoFile==null" src="../../../assets/front/images/icon/member.jpg"/>
          <img class="member-list-box" v-else :src="`/attachment/user/photo/${member.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
        </div>
        <div class="text-box">
          <div class="name-box">
            <a href="javascript:;" class="btnPeopleDtlOver" @click="onClickUser(member.userId)" :style="idx===overIdx? 'font-weight:bold':''">
              <span class="icon-new st2" v-if="isNew(member.createdAt)"></span> {{ member.name }}
            </a>
          </div>
          <div class="part-box">
            {{ memberSubdivision(member.departmentGroup) }}
            {{ memberClub(member.clubGroup )}}
          </div>
        </div>
      </div>
    </div>
    <!-- class : pagination (S) -->
    <DgPagination v-model="pagination" :value="memberList" @pageSelected="pageSelected"/>
    <!-- class : pagination (E) -->

    <CommonPopup v-if="memberPopupOpen" :tap="popupTab" :userId="userId" @click:bgDimClose="popupMemberList" ></CommonPopup>

  </div>
  <!-- id : contents : (E) -->
</template>

<script>
import groupApi from "@/api/GroupApi";
import userApi from "@/api/UserApi";
import CommonPopup from "@/views/include/common_popoup";
import DgPagination from '@/views/component/DgPagination'

export default {
  name: "MemberList",
  components:{
    CommonPopup,
    DgPagination
  },
  data(){
    return {
      currentTab:0,
      tabs:['회원현황','신규회원','연간일정'],
      memberPopupOpen: false,
      popupTab: 'detail',
      overIdx: null,
      managerItems:[],
      departmentItems:[],
      clubItems:[],
      memberList:[],
      userId: null,

      param: {
        searchColumn: 'all',
        searchKeyword: null,
        searchManger:'',
        searchDepartment:'',
        searchClub:'',
        page: 1
      },

      pagination:{
        currentPage: 1,
        pageSize:10,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },
    }
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false
    },
    reload:{
      type:Boolean
    }
  },
  created(){
    this.getReady();
  },
  watch:{
    isOpen(e){
      if(!e){
        this.memberPopupOpen=false;
      }
    },
    reload(e){
      if(e) {
        this.getReady();
      }
    }
  },
  methods:{
    getReady(){
      /*
      groupApi.managerList(3).then(data => {
        this.managerItems = data;
      });*/
      groupApi.flatList(1).then(data => {
        this.departmentItems = data;
      });
      groupApi.flatList(2).then(data => {
        this.clubItems = data;
      });

      //this.param.searchColumn = this.$route.query.searchColumn!=''?this.$route.query.searchColumn:'all';
      if(this.$route.query.searchColumn!=''){
        this.param.searchColumn = this.$route.query.searchColumn;
      }else{
        delete this.param.searchColumn;
      }
      this.param.searchKeyword = this.$route.query.searchKeyword;
      this.param.searchManger = '';
      this.param.searchDepartment = '';
      this.param.searchClub = '';
      this.onClickSearch();
      this.$emit('click:refreshReload', false);
    },
    pageSelected(pagination){
      this.pagination.currentPage = pagination.currentPage;
      this.onClickSearch();
    },
    onClickUser(userId){
      this.userId = userId
      this.popupMemberList(true)
    },
    onMouseOver(idx){
      this.overIdx=idx;
    },
    onMouseLeave(){
      this.overIdx=null;
    },
    popupMemberList(e) {
      this.memberPopupOpen = e
      this.$emit('click:bgDim', this.memberPopupOpen)
    },
    onClickBeforeSearch(){
      this.pagination={
        currentPage: 1,
        pageSize:10,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },

      delete this.param.searchColumn;
      this.onClickSearch();
    },
    onClickSearch(){
      const params = {...this.param};

      params.fromDate = null;
      params.toDate = null;
      params.searchState = 'STATE_NORMAL'
      params.size = this.pagination.pageSize
      params.page = this.pagination.currentPage
      userApi.list(params).then(data => {
        this.memberList = data;
        this.pagination.totalElements=data.totalElements
        let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0) ;
        this.pagination.totalPages = totalPages;
      })
    },
    onKeydown(e){
      if(e.keyCode===13){
        this.onClickBeforeSearch();
      }
    },
    onClickTab(val){
      switch (val){
        case 0 :
          this.$router.push(`/member`).catch(()=>{});
          break;
        case 1 :
          this.$router.push(`/memberNew`).catch(()=>{});
          break;
        case 2 :
          this.$router.push(`/schedule`).catch(()=>{});
          break;
      }
    },
    phoneFormatter(val){
      if (val != null) {
        if (val.length == 11) {
          return val.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        } else if (val.length == 8) {
          return val.replace(/(\d{4})(\d{4})/, "$1-$2");
        } else {
          if (val.indexOf("02") == 0) {
            return val.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          } else {
            return val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          }
        }
      }
    },
    memberSubdivision(val){
      if(val!=null) {
        let subdivisionLength = val.length
        let userSubdivision = subdivisionLength > 0 ? val[0].groupName : ''
        return userSubdivision
      }
    },
    memberClub(val){
      if(val!=null) {
        let clubLength = val.length
        let userClubs = clubLength > 0 ? val[0].groupName : ''
        if (clubLength > 1) {
          userClubs += " 외 " + (clubLength - 1)
        }
        return userClubs
      }
    },
    isNew(val) {
      //오늘로부터 6개월 내에 가입한 사람은 new 아이콘
      let moment = require('moment')
      let createdAt = moment(val)

      let calculDate = moment().subtract(6,'M')
      return createdAt > calculDate? true:false
    }
  }
}
</script>

<style scoped>

</style>