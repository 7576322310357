<template>

  <!-- id : contents : (S) -->
  <div id='contents' >
    <div class="sub-visual-wrap st-member">
      <div class="inner-base-box">
        <h2 class="h2">{{ menuName }}</h2>
        <ol>
          <li style="background: none;">홈</li>
          <li>{{ menuName }}</li>
          <li>{{ currentTabMenu.menuName }}</li>
        </ol>
      </div>
    </div>
    <!-- class : content-wrap : (S) -->
    <section class="content-wrap st-center">
      <div class="inner-base-box mb-pd-none">
        <div class="tab-menu-box">
          <a href="javascript:;" class="btn-menu " :class="{open : this.$store.state.tapOpen === true}" @click="openTab()">탭열기버튼</a>
          <span class="t-txt">MENU</span>
        </div>
        <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
          <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
          <div class="sub-tab-menu-box st-member">
            <a href="javascript:;" v-for="(tab, index) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTab(tab, index)" :key="tab.boardId">{{ tab.menuName }}</a>
          </div>
          <div class="sub-tab-mb-title">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTabMobile()" :key="tab.boardId">{{ tab.menuName }}</a>
          </div>
          <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
            <a href="javascript:;" v-for="(tab, index) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTab(tab, index)" :key="tab.boardId">{{ tab.menuName }}</a>
          </div>
        </div>
      </div>
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box mb-pd-none">
          <div class="subpage-title-box st-noline">
            <h3 class="h3">{{ tabMenuName }}</h3>
          </div>

          <MemberList v-if="tabIndex==0" @click:bgDim="popupMemberList" :isOpen="isOpen" :reload="reload" @click:refreshReload="refreshReload"></MemberList>
          <MemberNew v-if="tabIndex==1" @click:bgDim="popupMemberList" :isOpen="isOpen" :reload="reload" @click:refreshReload="refreshReload"></MemberNew>
          <Schedule v-if="tabIndex==2 && boardId != null" @click:bgDim="popupMemberList" :reload="reload" @click:refreshReload="refreshReload" :boardId="boardId"></Schedule>

        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import menuApi from "@/api/MenuApi";
import MemberNew from "@/views/board/member/member_new";
import MemberList from "@/views/board/member/member_list";
import Schedule from "@/views/board/member/schedule";

export default {
  name: "MemberIndex",
  components:{
    MemberNew,
    MemberList,
    Schedule
  },
  computed: {
    tabMenuName() {
      return this.tabs[this.tabIndex]?.menuName??'-'
    }
  },
  data(){
    return {
      tabIndex:0,
      parentMenu:0,
      tabs:[],
      currentTabMenu: {},
      memberPopupOpen: false,
      boardId:null,
      reload:false,
      menuName : null,
      tablistOpen:false,
    }
  },
  created() {
    this.tabIndex = this.$route.params.tapNo
    this.parentMenu = this.$route.query.upper
    this.getMenu();
  },
  watch:{
    $route(){
      this.tabIndex = this.$route.params.tapNo
      this.getMenu();
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods:{
    getMenu(){
      menuApi.getMenu(this.parentMenu).then(data=>{
        this.menuName=data.menuName
        this.tabs = data.childMenus;    //인사말, 활동소개, 조직도 etc
        this.currentTabMenu = this.tabs[this.tabIndex]
        this.boardId = this.currentTabMenu.boardId
        // this.boardName = this.currentTabMenu.childMenus[0].menuName
        // this.getGroupInfo();
      })
    },
    onClickTab(tab, index){
      if(this.currentTabMenu.menuId == tab.menuId) {
        this.reload = true;
      }
      this.$router.push(this.tabs[index].url+`?upper=${this.parentMenu}&menu=${tab.menuId}`).catch(()=>{});
    },
    popupMemberList(e) {
      this.memberPopupOpen = e
      this.$emit('click:bgDim', this.memberPopupOpen)
    },
    refreshReload(e){
      this.reload=e;
    },
    /*탭 오픈*/
    openTab(){
      this.$store.commit('setTapOpen')
    },
    onClickTabMobile(){
      if(this.tablistOpen){
        this.tablistOpen = false;
      }else{
        this.tablistOpen = true;
      }
    },
    goback(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>